/** @jsx jsx */
import React from "react"
import Layout from "../components/Layout"
import {
  Box,
  Heading,
  Grid,
  Text,
  Label,
  Input,
  Textarea,
  Button,
  jsx,
} from "theme-ui"
import SEO from "../components/SEO"
import SectionHeader from "../components/SectionHeader"
import PageHeader from "../components/PageHeader"
import SubInfoBox from "../components/SubInfoBox"
import Values from "../components/Careers/Values"

const CareerPage = () => (
  <Box>
    <SEO title="Careers" />
    <Layout>
      <PageHeader>
        <Text
          sx={{ fontSize: [3, 5], fontWeight: "bold", textAlign: "center" }}
        >
          Join us in our journey
        </Text>
        <Text
          sx={{ fontSize: [3, 5], fontWeight: "bold", textAlign: "center" }}
        >
          Join Sarkar
        </Text>
      </PageHeader>
      <Box mt={[3, 5]} mb={4}>
        <SectionHeader noBorder={true}>CAREERS</SectionHeader>
      </Box>
      <SubInfoBox>
        We are in the formative process of our Institute and are seeking like
        minded individuals who have strong sense of passion to coach the future
        aspirants of our nation.
      </SubInfoBox>

      {/* Values */}
      <Box my={[-3, 7]}>
        <Values />
      </Box>

      {/* Apply job form */}
      <Box bg="#EBEBEB6F" mt={3} py={[4, 5]}>
        <Grid columns={[1, 2]}>
          <Heading p={[3, 5, 7]} sx={{ fontSize: [2, 5] }}>
            APPLY JOB FOR SARKAR IAS ACADEMY
          </Heading>
          <Box px={3}>
            <Heading
              sx={{ fontSize: [2, 5], marginTop: ["-10px", "auto"] }}
              variant="caps"
            >
              Get in Touch
            </Heading>
            <Text
              sx={{ fontSize: [0, 1], marginTop: ["10px", "auto"] }}
              variant="secondary"
            >
              Please fill in the form below, Our team will get in touch with you
              shortly.
            </Text>
            <Box pt={[2, 4]} pb={3} pr={5}>
              <form
                id="careerForm"
                name="career"
                method="post"
                data-netlify="true"
                encType="multipart/form-data"
                action="/thankYou/career"
              >
                <Grid
                  columns={[1, 1, 2]}
                  sx={{ gridRowGap: 2, gridColumnGap: 4 }}
                >
                  <Box>
                    <Label sx={{ fontSize: [0, 1] }} htmlFor="name">
                      Name
                    </Label>
                    <Input type="text" name="name" id="name" required />
                  </Box>
                  <Box>
                    <Label sx={{ fontSize: [0, 1] }} htmlFor="role">
                      Role
                    </Label>
                    <Input type="text" name="role" id="role" />
                  </Box>
                  <Box>
                    <Label sx={{ fontSize: [0, 1] }} htmlFor="email">
                      Email
                    </Label>
                    <Input type="text" name="email" id="email" required />
                  </Box>
                  <Box>
                    <Label sx={{ fontSize: [0, 1] }} htmlFor="mobile">
                      Mobile
                    </Label>
                    <Input type="text" name="mobile" id="mobile" required />
                  </Box>
                </Grid>
                <Box mt={2}>
                  <Label sx={{ fontSize: [0, 1] }} htmlFor="message">
                    Message
                  </Label>
                  <Textarea name="message" id="message" required />
                </Box>
                <Box mt={2}>
                  <Label sx={{ fontSize: [0, 1] }} htmlFor="resume">
                    Upload Resume
                  </Label>
                  <Input
                    sx={{ fontSize: [0, 1] }}
                    type="file"
                    name="resume"
                    id="resume"
                    accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    required
                  />
                </Box>
                <Button sx={{ fontSize: [0, 1] }} mt={3}>
                  Submit
                </Button>
                <input type="hidden" name="form-name" value="career" />
              </form>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Layout>
  </Box>
)

export default CareerPage
