import React from "react"
import { Box, Text, Grid, Divider } from "theme-ui"

interface ValueHeaderProps {
  header: string
  color: string
}

const ValueHeader = (props: ValueHeaderProps): JSX.Element => {
  const { header, color } = props
  return (
    <Box sx={{ lineHeight: "1.3" }}>
      <Text sx={{ fontSize: [1, 2, 3] }} color={color}>
        We value
      </Text>
      <Text sx={{ fontSize: [2, 3, 5], fontWeight: "bold" }}>{header}</Text>
    </Box>
  )
}

interface ValueCardProps {
  header: string
  body: string
  headerColor: string
  bodyColor: string
  backgroundColor: string
  dividerColor: string
  leftTriangle?: boolean
  rightTriangle?: boolean
}

const ValueCard = (props: ValueCardProps): JSX.Element => {
  const {
    header,
    body,
    headerColor,
    bodyColor,
    backgroundColor,
    dividerColor,
    leftTriangle,
    rightTriangle,
  } = props
  return (
    <Box
      p={4}
      sx={{ textAlign: "center", position: "relative" }}
      bg={backgroundColor}
    >
      <ValueHeader header={header} color={headerColor} />
      <Divider
        sx={{ width: "100px", marginLeft: "auto", marginRight: "auto" }}
        my={[3,4]}
        color={dividerColor}
      />
      <Text sx={{fontSize:[0,1]}} color={bodyColor}>{body}</Text>

      {/* Right Triangle */}
      {rightTriangle && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            right: "0%",
            transform: "translate(100%, -50%)",
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              width: "0",
              height: "0",
              borderTop: "15px solid transparent",
              borderBottom: "15px solid transparent",
              borderLeft: `15px solid ${backgroundColor}`,
            }}
          />
        </Box>
      )}

      {/* Left Triangle */}
      {leftTriangle && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "0%",
            transform: "translate(-100%, -50%)",
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              width: "0",
              height: "0",
              borderTop: "15px solid transparent",
              borderBottom: "15px solid transparent",
              borderRight: `15px solid ${backgroundColor}`,
            }}
          />
        </Box>
      )}
    </Box>
  )
}

const Values = (): JSX.Element => {
  return (
    <Grid gap={0} columns={[1, 1, 3]}>
      <ValueCard
        header="Transparency"
        body="We will be completely transparent in all our actions. We want all our employees to know the direction that we are heading and take an active part in it. We believe being transparent increases the trust."
        headerColor="#CBB722"
        bodyColor="#898686"
        backgroundColor="#EBEBEB29"
        dividerColor="#7070706A"
        rightTriangle={true}
      />
      <ValueCard
        header="Trust & Respect"
        body="Sarkar IAS Academy runs on trust. We really trust our employees and expect the same. We believe everyone is equal and treat everyone the same. We never compromise on our core principles."
        headerColor="#CBB724"
        bodyColor="#000"
        backgroundColor="#FEE8E8"
        dividerColor="#707070A5"
      />
      <ValueCard
        header="Passion & Attitude"
        body="We are highly passionate when it comers to work. We want such equally passionated people to join us in serving our mission. Together lets make a difference"
        headerColor="#CBB724"
        bodyColor="#FFF"
        backgroundColor="#FA0000B7"
        dividerColor="#FFFFFFD3"
        leftTriangle={true}
      />
    </Grid>
  )
}

export default Values
